import React from 'react';

export default function CancellationRefundPolicy() {
  return (
    <div className="max-w-3xl mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Cancellation & Refund Policy</h1>
      <p className="text-sm text-gray-500 mb-8">Last updated on Oct 10th 2024</p>

      <p className="mb-4">
        TEERTHAM TECHNOLOGIES PRIVATE LIMITED believes in helping its customers as far as possible,
        and has therefore a liberal cancellation policy. Under this policy:
      </p>

      <ul className="list-disc list-inside space-y-4">
        <li>
          Cancellations will be considered only if the request is made within 7 days of placing the order.
          However, the cancellation request may not be entertained if the orders have been communicated to the
          vendors/merchants and they have initiated the process of shipping them.
        </li>
        <li>
          TEERTHAM TECHNOLOGIES PRIVATE LIMITED does not accept cancellation requests for perishable items
          like flowers, eatables, etc. However, refund/replacement can be made if the customer establishes
          that the quality of product delivered is not good.
        </li>
        <li>
          In case of receipt of damaged or defective items please report the same to our Customer Service team.
          The request will, however, be entertained once the merchant has checked and determined the same at
          his own end. This should be reported within 7 days of receipt of the products.
        </li>
        <li>
          In case you feel that the product received is not as shown on the site or as per your expectations,
          you must bring it to the notice of our customer service within 7 days of receiving the product.
          The Customer Service Team after looking into your complaint will take an appropriate decision.
        </li>
        <li>
          In case of complaints regarding products that come with a warranty from manufacturers, please refer
          the issue to them.
        </li>
        <li>
          In case of any Refunds approved by the TEERTHAM TECHNOLOGIES PRIVATE LIMITED, it’ll take 3-5 days
          for the refund to be processed to the end customer.
        </li>
      </ul>

      <p className="text-sm text-gray-500 mt-8">
        Disclaimer: The above content is created at TEERTHAM TECHNOLOGIES PRIVATE LIMITED’s sole discretion. Razorpay
        shall not be liable for any content provided here and shall not be responsible for any claims and liability
        that may arise due to merchant’s non-adherence to it.
      </p>
    </div>
  );
}
