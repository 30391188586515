import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const Footer = ({ handleScrollOrNavigate }) => {

    return (
        <div className="bg-orange-50 w-full">
            <div className="flex items-center justify-between w-full max-w-[calc(100%_-_400px)] mx-auto p-4">
                {/* Logo Section */}
                <div className="flex items-center">
                    <Link to="/">
                        <img
                            src="/logo.png" // Replace with your logo path
                            alt="Logo"
                            className="w-16 h-16"
                        />
                    </Link>
                </div>

                {/* Center Buttons */}
                <div className="flex space-x-8">
                    <div>
                        <h2 className="orange-500 text-xl font-bold mb-4">Menu</h2>
                        <ul className="space-y-2">
                            <li>
                                <button onClick={() => handleScrollOrNavigate('about')}>About Us</button>
                            </li>
                            <li>
                                <button onClick={() => handleScrollOrNavigate('feature')}>How It Works</button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="orange-500 text-xl font-bold mb-4">Quick Links</h2>
                        <ul className="space-y-2">
                            <li>
                                <Link to="/terms">Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to="/privacy">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/refund">Cancellation and Refund</Link>
                            </li>
                            <li>
                                <Link to="/shipping">Shipping and Delivery</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Store Buttons */}
                <div className="flex space-x-4">
                    <img src="/playstore.png" alt="Get it on Google Play" className="w-32 h-auto" />
                    <img src="/appstore.png" alt="Download on the App Store" className="w-32 h-auto" />
                </div>
            </div>
        </div>
    );
};

export default Footer;
