import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const Header = ({ handleScrollOrNavigate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white w-full">
      <div className="flex items-center justify-between w-full max-w-[calc(100%_-_400px)] mx-auto p-4">
        {/* Logo Section */}
        <div className="flex items-center">
          <Link to="/">

            <img
              src="/logo.png" // Replace this with the actual path to your logo image
              alt="Logo"
              className="w-16 h-16" // Adjust width and height as necessary
            />
          </Link>
        </div>

        {/* Center Buttons */}
        <div className="flex space-x-8">
          <button onClick={() => handleScrollOrNavigate('about')} className="text-gray-700 font-semibold hover:text-orange-500 focus:outline-none">
            About Us
          </button>
          <button onClick={() => handleScrollOrNavigate('feature')} className="text-gray-700 font-semibold hover:text-orange-500 focus:outline-none">
            How It Works
          </button>
        </div>

        {/* Contact Us Button */}
        <div>
          <button className="px-6 py-2 bg-gradient-to-r from-orange-500 to-orange-300 text-white font-semibold rounded-full shadow-md hover:from-orange-600 hover:to-orange-400 focus:outline-none" onClick={openModal}>
            Contact us
          </button>
        </div>
      </div>
      {isModalOpen && (
         <div className="fixed inset-0 flex items-start justify-center bg-black bg-opacity-50">
         <div className="bg-white rounded-lg w-1/2 p-8 mt-10 relative max-w-lg">
           <button
             onClick={closeModal}
             className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-2xl font-bold"
           >
             &times;
           </button>
           <h2 className="text-xl font-bold mb-6">Contact Us</h2>
           <p className="text-gray-700 mb-2">
             <strong>Email:</strong> admin@teertham.org
           </p>
           <p className="text-gray-700">
             <strong>Phone:</strong> 01140846851
           </p>
         </div>
       </div>
      )}
    </div>
  );
};

export default Header;
