import React, { useRef, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Header from './component/header';
import HomePage from './component/homePage';
import Footer from './component/footer';
import TermsConditions from './component/pages/terms-conditions';
import PrivacyPolicy from './component/pages/privacy';
import CancellationRefundPolicy from './component/pages/CancellationRefundPolicy';
import ShippingDeliveryPolicy from './component/pages/ShippingDeliveryPolicy';
import ScrollToTop from './component/scrollToTop';
import ContactForm from './component/contactForm';

function App() {
  const aboutusRef = useRef(null);
  const featureRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollToSection = location.state?.scrollTo;

    // Scroll to the correct section if on the homepage
    if (location.pathname === '/') {
      if (scrollToSection === 'about' && aboutusRef.current) {
        aboutusRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (scrollToSection === 'feature' && featureRef.current) {
        featureRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleScrollOrNavigate = (section) => {
    if (window.location.pathname === '/') {
        // If already on the homepage, scroll directly
        const refToScroll = section === 'about' ? aboutusRef : featureRef;
        if (refToScroll.current) {
            refToScroll.current.scrollIntoView({ behavior: 'smooth' });
        }
    } else {
        // If on another page, navigate to homepage and set scroll target in state
        navigate('/', { state: { scrollTo: section } });
    }
};

  return (
    <>
    <ScrollToTop/>
    <Routes>
      <Route
        path="/"
        element={
          <div className="app">
            <Header handleScrollOrNavigate={handleScrollOrNavigate} />
            <HomePage aboutusRef={aboutusRef} />
            <div ref={featureRef} className="items-center py-10">
              <img src="/how-it-works.svg" className="mx-auto" width={900} />
            </div>
            {/* <ContactForm/> */}
            <Footer handleScrollOrNavigate={handleScrollOrNavigate} />
          </div>
        }
      />
      <Route
        path="/terms"
        element={
          <div className="app">
            <Header  handleScrollOrNavigate={handleScrollOrNavigate} />
            <TermsConditions />
            <Footer  handleScrollOrNavigate={handleScrollOrNavigate} />
          </div>
        }
      />
      <Route
        path="/privacy"
        element={
          <div className="app">
            <Header  handleScrollOrNavigate={handleScrollOrNavigate} />
            <PrivacyPolicy />
            <Footer  handleScrollOrNavigate={handleScrollOrNavigate} />
          </div>
        }
      />
      <Route
        path="/refund"
        element={
          <div className="app">
            <Header  handleScrollOrNavigate={handleScrollOrNavigate} />
            <CancellationRefundPolicy />
            <Footer  handleScrollOrNavigate={handleScrollOrNavigate} />
          </div>
        }
      />
      <Route
        path="/shipping"
        element={
          <div className="app">
            <Header  handleScrollOrNavigate={handleScrollOrNavigate} />
            <ShippingDeliveryPolicy />
            <Footer  handleScrollOrNavigate={handleScrollOrNavigate} />
          </div>
        }
      />
    </Routes>
    </>
  );
}

export default App;
