import React from 'react';

const HomePage = ({aboutusRef}) => {
  return (
    <div className="w-full bg-white">
      {/* Hero Section */}
      <section className="flex items-center justify-between w-full max-w-[calc(100%_-_400px)] mx-auto p-4">
        {/* Left Content */}
        <div className="flex flex-col items-start space-y-4 md:w-1/2">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-800">
            <span className="text-orange-500">Teertham</span>
          </h1>
          <h3 className="text-2xl md:text-2xl font-bold text-gray-800">
              Keeping Rituals Alive ! <br />
          </h3>
          <p className="text-gray-600">Get a spiritual & authentic puja experience at home, performed by experienced & certified Acharyas</p>
          <div className="flex space-x-4">
            <img src="/playstore.png" alt="Get it on Google Play" className="w-32 h-auto" />
            <img src="/appstore.png" alt="Download on the App Store" className="w-32 h-auto" />
          </div>
          {/* <p className="text-gray-800 font-semibold">Coming soon...</p> */}
        </div>

        {/* Right Content */}
        <div className="flex items-center justify-center mt-8 md:mt-0 md:w-1/2">
          <img src="/banner.png" alt="Mobile Image 2" className="" />
          {/* <img src="/Placeholder.png" alt="Mobile Image 1" className="w-40 h-auto" /> */}
        </div>
      </section>

      {/* About Us Section */}
      <section ref={aboutusRef} className="flex flex-col md:flex-row items-center justify-between py-16 px-6 bg-orange-50">
        {/* Left Images */}
        <div className="flex items-center space-x-4 md:w-1/2">
          <img src="/aboutus.png" alt="Mobile Image 1" className="" />
        </div>

        {/* Right Text */}
        <div className="mt-8 md:mt-0 md:w-1/2 space-y-4">
          <h2 className="text-4xl font-bold text-gray-800">
            About <span className="text-orange-500">Us</span>
          </h2>
          <p className="text-gray-600 leading-relaxed">
            Welcome to the Pooja Pandit App, your ultimate companion for spiritual guidance and rituals.
            We are dedicated to making Hindu traditions accessible and meaningful for everyone, whether you
            are at home or on the go.
          </p>
          <p className="text-gray-600 leading-relaxed">
            At Pooja Pandit, we believe in fostering a deep connection to spirituality while embracing modern
            technology. Our mission is to empower you to practice your faith with confidence and convenience.
          </p>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
